<template>
  <div class="static-main-page">
    <div class="static-main-page__max-width">
      <div class="w-100">
        <div v-html="text" class="m-20"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    text: `

    <style type="text/css">
                        p.p1 {
                            margin: 0.0px 0.0px 18.0px 0.0px;
                            font: 14.0px Helvetica;
                            color: #535353;
                        }

                        p.p2 {
                            margin: 0.0px 0.0px 6.0px 0.0px;
                            line-height: 14.2px;
                            font: 13.5px Helvetica;
                            color: #535353;
                        }

                        p.p3 {
                            margin: 0.0px 0.0px 10.2px 0.0px;
                            line-height: 14.2px;
                            font: 13.5px Helvetica;
                            color: #535353;
                        }

                        p.p4 {
                            margin: 0.0px 0.0px 10.2px 0.0px;
                            font: 14.0px Helvetica;
                            color: #535353;
                        }

                        p.p6 {
                            margin: 0.0px 0.0px 0.0px 17.2px;
                            font: 14.0px Helvetica;
                            color: #535353;
                            min-height: 14.0px
                        }

                        p.p7 {
                            margin: 0.0px 0.0px 0.0px 0.0px;
                            font: 14.0px Helvetica;
                            color: #535353;
                        }

                        p.p9 {
                            margin: 0.0px 0.0px 0.0px 0.0px;
                            font: 14.0px Helvetica;
                            color: #535353;
                            min-height: 14.0px
                        }

                        li.li5 {
                            margin: 0.0px 0.0px 0.0px 0.0px;
                            font: 14.0px Helvetica;
                            color: #535353
                        }

                        li.li8 {
                            margin: 0.0px 0.0px 10.2px 0.0px;
                            font: 14.0px Helvetica;
                            color: #535353
                        }

                        span.s1 {
                            font: 12.0px Symbol
                        }

                      
                        span.s3 {
                            color: #275ca0
                        }

                        ul.ul1 {
                            list-style-type: disc
                        }

                    </style>
                    <style type="text/css" id="isPasted">
                        p.p1 {
                            margin: 0.0px 0.0px 0.0px 0.0px;
                            font: 16.0px Helvetica;
                            color: #535353;
                        }

                        p.p2 {
                            margin: 0.0px 0.0px 0.0px 0.0px;
                            font: 16.0px Helvetica;
                            color: #535353;
                            min-height: 14.0px
                        }

                        p.p3 {
                            margin: 0.0px 0.0px 10.2px 0.0px;
                            font: 16.0px Helvetica;
                            color: #535353;
                        }

                        p.p7 {
                            margin: 0.0px 0.0px 0.0px 0.0px;
                            line-height: 13.2px;
                            font: 16.0px Helvetica;
                            color: #535353;
                        }

                        p.p8 {
                            margin: 0.0px 0.0px 0.0px 0.0px;
                            line-height: 13.2px;
                            font: 16.0px Helvetica;
                            color: #535353;
                            min-height: 16.0px
                        }

                        p.p9 {
                            margin: 0.0px 0.0px 10.2px 0.0px;
                            line-height: 13.2px;
                            font: 16.0px Helvetica;
                            color: #535353;
                        }

                        p.p10 {
                            margin: 0.0px 0.0px 0.0px 17.2px;
                            font: 16.0px Helvetica;
                            color: #535353;
                            min-height: 14.0px
                        }

                        p.p11 {
                            margin: 0.0px 0.0px 6.0px 0.0px;
                            line-height: 13.2px;
                            font: 16.0px Helvetica;
                            color: #535353;
                        }

                        p.p12 {
                            margin: 0.0px 0.0px 18.0px 0.0px;
                            font: 16.0px Helvetica;
                            color: #535353;
                        }

                        li.li4 {
                            margin: 0.0px 0.0px 0.0px 0.0px;
                            font: 16.0px Helvetica;
                            color: #275ca0
                        }

                        li.li5 {
                            margin: 0.0px 0.0px 0.0px 0.0px;
                            font: 16.0px Helvetica;
                            color: #535353
                        }

                        li.li6 {
                            margin: 0.0px 0.0px 0.0px 0.0px;
                            font: 16.0px Helvetica;
                            color: #0b4cb4
                        }

                        li.li13 {
                            margin: 0.0px 0.0px 10.2px 0.0px;
                            font: 16.0px Helvetica;
                            color: #535353
                        }

                        span.s1 {
                            color: #275ca0
                        }

                        span.s2 {
                            font: 10.0px Symbol;
                            color: #535353
                        }

                       
                        span.s4 {
                            color: #535353;
                        }

                        span.s5 {
                            font: 12.0px Symbol
                        }

                        span.s6 {
                            color: #275ca0;
                        }

                        span.s7 {
                            text-decoration: underline;
                            color: #0b4cb4
                        }

                        ul.ul1 {
                            list-style-type: disc
                        }

                    </style>
    <p class="p1"><strong>PRIVACY POLICY</strong></p>
                    <p class="p2"><br></p>
                    <p class="p3">Medical Job (hereinafter: <span class="s1"><strong>&bdquo;Data
                                Controller&rdquo;)</strong></span> is committed to fully respecting their clients&rsquo;
                        (doctors and medical personnel, hereinafter <span class="s1"><strong>&bdquo;Data
                                Subject&rdquo;</strong></span> or <span
                            class="s1"><strong>&bdquo;Candidate&rdquo;</strong></span>) rights concerning
                        data controlling. Accordingly, we manage the Data Subjects&rsquo; personal data in agreement
                        with the European Union&rsquo;s &nbsp;Act of 2016/679 on the protection of natural persons and
                        natural persons&rsquo; data management, and the free flow of this data (hereinafter: General
                        Data Protection Regulation or <span
                            class="s1"><strong>&bdquo;GDPR&rdquo;</strong></span>).</p>
                    <p class="p3">Data Controller&rsquo;s contact data:</p>
                    <ul class="ul1">
                        <li class="li4"><span class="s3"><strong>Name of Data
                                    Controller:</strong></span><span class="s4">&nbsp;Medical Job</span>
                        </li>
                        <li class="li4"><span class="s3"><strong>Representative of Data
                                    Controller:</strong></span><span class="s4">&nbsp;Elias El Salibi</span>
                        </li>
                        <li class="li5"><span class="s6"><strong>Address:</strong></span><span
                                class="s3">&nbsp;Zouk Mosbeh Lebanon</span></li>
                        <li class="li5"><span class="s6"><strong>Phone
                                    number:</strong></span><span class="s3">&nbsp;+9613375667</span></li>
                        <li class="li6"><span class="s6"><strong>E-mail
                                    address:</strong></span><span class="s4">&nbsp;<a
                                    href="mailto:info@medicaljob.me"><span
                                        class="s7">info@medicaljob.me</span></a></span></li>
                    </ul>
                    <p class="p2"><br></p>
                    <p class="p2"><br></p>
                    <p class="p7"><strong>1.PURPOSE OF DATA CONTROLLING, GENERAL DURATION</strong></p>
                    <p class="p8"><br></p>
                    <p class="p3">The purpose of data management is to successfully recruit the Data
                        Subjects to hospitals in the Gulf &amp; Mena Region (hereinafter: <span
                            class="s1"><strong>&bdquo;Recruitment&rdquo;</strong></span>), and the
                        transaction of relocation to the aim countries (hereinafter: <span
                            class="s1"><strong>&bdquo;Relocation&rdquo;</strong></span>), including its full
                        administration (hereinafter: <span
                            class="s1"><strong>&bdquo;Services&rdquo;</strong></span>). The Data Controller
                        manages the Data Subjects&rsquo; data from initial contact (using basic contact information)
                        through successful Recruitment to the transaction of Relocation, to the extent necessary for
                        certain Services. This whole process can last for over two years. After having completed the
                        Services, of the data management, in the interest the follow-up processing of rights and
                        obligations concerning the contract (hereinafter: <span
                            class="s1"><strong>&bdquo;Follow-up procedures&rdquo;</strong></span>) (based on
                        the contract with the hospital, follow-up on the candidate, legal obligations), and in the
                        interest of information and marketing, continues.</p>
                    <p class="p3">Follow-up procedures will make data management necessary even after the
                        completion of Services in order to upkeep legal obligations (local, and possibly Lebanese law)
                        and depending on the legal relationship established with the given hospital.</p>
                    <p class="p3">Furthermore, we will store the data necessary for contacting the Data
                        Subject, for further informing the Data Subject, or to inform them about further services
                        similar to the Services, i.e. marketing.</p>
                    <p class="p9"><strong>2.DATA CONTROLLING AND SCOPE OF CONTROLLED DATA</strong></p>
                    <p class="p3">The actual content of data controlling, the scope of personal data, its
                        time of preservation will be discussed service phase by phase in this privacy policy separately.
                    </p>
                    <p class="p9"><strong>3.LEGAL BASIS FOR DATA CONTROLLING</strong></p>
                    <p class="p3">In all phases of the process it is the particular (in case of special
                        category personal data) and voluntary consent of the Data Subject based on their previous
                        information. Furthermore, the legal basis of data controlling can be:</p>
                    <ul class="ul1">
                        <li class="li5"><span class="s3">the completion of the contract with the
                                Data Subject concerning our Services,</span></li>
                        <li class="li5"><span class="s3">the completion of all legal obligations
                                of the Data Controller.</span></li>
                    </ul>
                    <p class="p10"><br></p>
                    <p class="p10"><br></p>
                    <p class="p9"><strong>4.THE POSSIBLE REASONS OF THE INCOMPLETION OF DATA
                            CONTROLLING</strong></p>
                    <p class="p3">The controlling of the personal data, its communication towards the Data
                        Controller happens in order to successfully provide the Services, these personal data are
                        reasonably necessary for the Data Controller&rsquo;s Services, thus not providing data can
                        result in the incompletion of the Services.</p>
                    <p class="p7"><strong>5.DATA CONTROLLING DURING RECRUITMENT</strong></p>
                    <p class="p11"><strong>5.1.DATA CONTROLLING NECESSARY FOR ESTABLISHING CONTACT</strong>
                    </p>
                    <p class="p9"><strong>5.1.1.THE PURPOSE OF DATA CONTROLLING</strong></p>
                    <p class="p12">The Data Controller&rsquo;s main activity is recruiting doctors and
                        medical personnel to member countries of the Gulf &amp; Mena Regions, for which activity the
                        Data Controller establishes contact with doctors and medical personnel with a degree from one of
                        the member countries of the Gulf &amp; Mena Regions. These criteria are necessary because of the
                        accepting country&rsquo;s authorization processes. The Data Controller contacts the Data
                        Subjects by using publicly available data, or the voluntary applicants&rsquo; given contact
                        data, to offer opportunities that can advance them financially, in their standard of living
                        regarding their careers, and their personal life as well.</p>
                    <p class="p9"><strong>5.1.2.SCOPE OF CONTROLLED DATA</strong></p>
                    <p class="p3">The Data Subject&rsquo;s name, and contact data (phone number, email
                        address), the candidate&rsquo;s medical specialty, and subspecialty, other degrees, professional
                        experience, language knowledge, sex, nationality, the CV provided by the Data Subject, whether
                        it is in Lebanese, English, or another language, and the pictures of the Data Subject.</p>
                    <p class="p12">In order to provide our Services, we control data on public social media
                        sites (LinkedIn, Facebook, Instagram, and other social media sites) also concerning the data
                        published by the Data Subject, on their own profile, which includes the registration date in the
                        given public database.</p>
                    <p class="p9"><strong>5.1.3.LEGAL BASIS FOR CONTROLLED DATA</strong></p>
                    <p class="p3">Primarily based on the previous information of the Data Subject, their
                        specific (in case of special category personal data) and voluntary consent, both with regards to
                        publicly available data, and the data provided to us by the Data Subject. Furthermore, the legal
                        basis of data controlling can be:</p>
                    <ul class="ul1">
                        <li class="li5"><span class="s3">the completion of the contract with the
                                Data Subject concerning our Services,</span></li>
                        <li class="li5"><span class="s3">the completion of all legal obligations
                                of the Data Controller.</span></li>
                    </ul>
                    <p class="p9"><strong>5.1.4.RETENTION TIME OF CONTROLLED DATA</strong></p>
                    <p class="p3">The duration of Service providing, which can exceed two years.</p>
                    <p class="p12">Furthermore, besides the legal bases listed in point 3 above (completion
                        of contract, legitimate interest, and the completion of the Data Controller&rsquo;s legal
                        obligation) require that the scope of personal data (basically the data required for personal
                        identification and contact) be controlled even after the completion of Service providing.</p>
                    <p class="p9"><strong>5.1.5. DATA FORWARDING</strong></p>
                    <p class="p3">According to GDPR forwarding data to member states of the Gulf &amp; Mena
                        Regions does not have data security risks, thus the Data Subject&rsquo;s specific consent is not
                        necessary from this standpoint.</p>
                    <p class="p12">For technical processing and storing, the personal data is controlled by a
                        data processor whose servers are in the United States of America (the state of California).
                        Based on our experience we can maximize the effectiveness of our inner processes with this
                        service provider. Considering that data forwarding outside the Gulf &amp; Mena Regions requires
                        permission, we ask for the Data Subject&rsquo;s consent for this particular forwarding.</p>
                    <p class="p9"><strong>5.1.6. DATA PROCESSOR</strong></p>
                    <p class="p3">Given that our Services are complex, time-consuming activities requiring
                        special expertise, we use subcontractors to provide them. We are accountable for our
                        subcontractors the same way we are for ourselves. Subcontractors acting on our behalf will also
                        treat the personal data of the Data Subjects in the following cases. In the words of GDPR, they
                        are considered to be Data Processors.</p>
                    <p class="p1">During the <span class="s1"><strong>contacting
                                phase</strong></span> the data processing areas (where the controlling of the personal
                        data is done by subcontractors):</p>
                    <ul class="ul1">
                        <li class="li5"><span class="s3">Employment company</span></li>
                        <li class="li13"><span class="s3">External salesmen</span></li>
                    </ul>
                    <p class="p12">We only use data processors who provide appropriate guarantee compliance
                        with the data controlling requirements of GDPR, and proper technical and organizational measures
                        that ensure the protection of the Data Subjects&rsquo; rights.</p>
                    <p class="p11"><strong>5.2.RECRUITMENT PHASE</strong></p>
                    <p class="p9"><strong>5.2.1.PURPOSE OF DATA CONTROLLING</strong></p>
                    <p class="p12">The Data Controller prepares professional material about the candidates
                        included in the recruitment process. This document contains the candidates&rsquo; professional
                        background, education, and acquired employment experience. The Data Controller manages this data
                        in a form that they comply as much as possible with the recruitment destination country&rsquo;s
                        inner standards. The documents are prepared in a form that maximizes the Data Subject&rsquo;s
                        possibility of employment in the destination country. The primary objective of data controlling
                        is to provide the most comprehensive picture of the Data Subject for potential future employers,
                        and to provide them with new employment possibilities.</p>
                    <p class="p9"><strong>5.2.2. SCOPE OF CONTROLLED DATA</strong></p>
                    <p class="p12">Those data controlled according to point 5.1.2, and the Data Controller
                        also manages those data contained in the Data Subject&rsquo;s CV and motivation letter. These
                        can be birthday, address, language knowledge, Skype account, notice period, relationship status,
                        or profile picture. Besides these data, the Data Controller manages detailed information on the
                        Data Subject&rsquo;s professional knowledge and educational background. For medical activities,
                        official documentation is necessary in the Gulf &amp; Mena Regions, thus Medicaljob manages the
                        Data Subject&rsquo;s practice permit and medical diploma to ensure regulatory compliance. In
                        case the Data Subject is from outside the Gulf &amp; Mena Regions, their certificate of
                        conformity and homologation is managed as well.</p>
                    <p class="p9"><strong>5.2.3.LEGAL BASIS FOR CONTROLLED DATA</strong></p>
                    <p class="p3">Primarily based on the previous information of the Data Subject, their
                        specific (in case of special category personal data) and voluntary consent, both with regards to
                        publicly available data, and the data provided to us by the Data Subject. Furthermore, the legal
                        basis of data controlling can be:</p>
                    <ul class="ul1">
                        <li class="li5"><span class="s3">the completion of the contract with
                                the Data Subject concerning our Services,</span></li>
                        <li class="li5"><span class="s3">the completion of all legal
                                obligations of the Data Controller.</span></li>
                    </ul>
                    <p class="p10"><br></p>
                    <p class="p9"><strong>5.2.4. RETENTION TIME OF CONTROLLED DATA</strong></p>
                    <p class="p3">The time necessary to provide the Services, which could exceed two years.
                    </p>
                    <p class="p3">Furthermore, besides the legal bases listed in point 3 above (completion
                        of contract, legitimate interest, and the completion of the Data Controller&rsquo;s legal
                        obligation) require that the scope of personal data (basically the data required for personal
                        identification and contact) be controlled even after the completion of Service providing.</p>
                    <p class="p12">Contact data will be retained for marketing purposes until the Data
                        subject does not request their erasure.</p>
                    <p class="p9"><strong>5.2.5. DATA FORWARDING</strong></p>
                    <p class="p3">Your personal data will be forwarded to healthcare institutions in the
                        Gulf &amp; Mena Regions member states for the provision of the Service, of which we will inform
                        the Data Subject. &nbsp;If the Data Subject expressly consents to this, your personal
                        information will be passed on to other intermediary companies, thereby increasing the scope for
                        employment possibilities.</p>
                    <p class="p3">According to GDPR forwarding data to member states of the Gulf &amp; Mena
                        Regions does not have data security risks, thus the Data Subject&rsquo;s specific consent is not
                        necessary from this standpoint.</p>
                    <p class="p12">For technical processing and storing, the personal data is controlled by
                        a data processor whose servers are in the United States of America (the state of California).
                        Based on our experience we can maximize the effectiveness of our inner processes with this
                        service provider. Considering that data forwarding outside the Gulf &amp; Mena Regions requires
                        permission, we ask for the Data Subject&rsquo;s consent for this particular forwarding.</p>
                    <p class="p9"><strong>5.2.6.DATA PROCESSOR</strong></p>
                    <p class="p3">Given that our Services are complex, time-consuming activities requiring
                        special expertise, we use subcontractors to provide them. We are accountable for our
                        subcontractors the same way we are for ourselves. Subcontractors acting on our behalf will also
                        treat the personal data of the Data Subjects in the following cases. In the words of GDPR, they
                        are considered to be Data Processors.</p>
                    <p class="p1">During the <span class="s1"><strong>recruitment
                                phase</strong></span> the data processing areas (where the controlling of the personal
                        data is done by subcontractors):</p>
                    <p class="p2"><br></p>
                    <ul class="ul1">
                        <li class="li13"><span class="s3">Khaled Saadi</span></li>
                    </ul>
                    <p class="p12">We only use data processors who provide appropriate guarantee compliance
                        with the data controlling requirements of GDPR, and proper technical and organizational measures
                        that ensure the protection of the Data Subjects&rsquo; rights.</p>
                    <p class="p11"><strong>5.3.INTERVIEW LEVEL DATA CONTROLLING</strong></p>
                    <p class="p9"><strong>5.3.1.THE PURPOSE OF DATA CONTROLLING</strong></p>
                    <p class="p12">The purpose of data controlling is for the Data Controller to be able to
                        present a more comprehensive picture about the Data Subject to the potential employers, for this
                        they prepare a detailed professional material called a Portfolio, thus aiding the Data
                        Subject&rsquo;s search for employment. Due to the international travels that are included in the
                        interview process, the Data Controller manages data necessary for flights, car rentals, and
                        accommodation bookings to make the Data Subject&rsquo;s stay in the destination country easier.
                    </p>
                    <p class="p9"><strong>5.3.2.SCOPE OF CONTROLLED DATA</strong></p>
                    <p class="p3">Those data controlled according to point 5.2.2 and all that is said by
                        the Data Subject during the motivational interview concerning their motivation, dedication, and
                        personal preferences, and the data covered in the personality test. Furthermore, the Data
                        Controller manages those data that are necessary for the organization of international travels,
                        for example, the Data Subject&rsquo;s ID number, or passport information.</p>
                    <p class="p12">A so-called NEO PI-R test is applied for the personality test, during
                        which the Data Subject&rsquo;s answers (considered personal data) are processed and evaluated by
                        a software (considered profiling). The data given during testing is used to analyze or predict
                        professional performance, possibly state of health, personal preferences, interests,
                        trustworthiness, behavior, thus during the use of NEO PI-R medical data is controlled as well.
                    </p>
                    <p class="p9"><strong>5.3.3.LEGAL BASIS FOR CONTROLLED DATA</strong></p>
                    <p class="p3">Primarily based on the previous information of the Data Subject, their
                        specific (in case of special category personal data) and voluntary consent, both with regards to
                        publicly available data, and the data provided to us by the Data Subject. Furthermore, the legal
                        basis of data controlling can be:</p>
                    <ul class="ul1">
                        <li class="li5"><span class="s3">the completion of the contract with
                                the Data Subject concerning our Services,</span></li>
                        <li class="li5"><span class="s3">the completion of all legal
                                obligations of the Data Controller.</span></li>
                    </ul>
                    <p class="p10"><br></p>
                    <p class="p9"><strong>5.3.4.RETENTION TIME OF CONTROLLED DATA</strong></p>
                    <p class="p3">Time necessary for the provision of Services, foreseeably 36 months.</p>
                    <p class="p12">Furthermore, besides the legal bases listed in point 3 above (completion
                        of contract, legitimate interest, and the completion of the Data Controller&rsquo;s legal
                        obligation) require that the scope of personal data (basically the data required for personal
                        identification and contact) be controlled even after the completion of Service providing.</p>
                    <p class="p9"><strong>5.3.5.DATA FORWARDING</strong></p>
                    <p class="p3">Your personal data during the provision of Services will be forwarded to
                        medical facilities in the Gulf &amp; Mena Regions, of which we will send the Data Subjects
                        regular summaries.</p>
                    <p class="p12">According to GDPR data forwarding to the member states of the Gulf &amp;
                        Mena Regions does not have data security risks, so no consent is needed from the Data Subject.
                        The Data Subject&rsquo;s personal data, including the NEO PI-R test, and the data given in it,
                        will be sent to Psychological Assessment Resources &ndash; PAR, Inc. in the USA, which agrees
                        with the conditions in Article 1 of the EU Committee 2016/1250 Act, thus they can guarantee GDPR
                        compatible data protection for this type of data forwarding. For this reason, the Data
                        Subject&rsquo;s specific consent is not necessary for the forwarding of the test and the
                        personal data given in it.</p>
                    <p class="p9"><strong>5.3.6.DATA PROCESSOR</strong></p>
                    <p class="p3">Given that our Services are complex, time-consuming activities requiring
                        special expertise, we use subcontractors to provide them. We are accountable for our
                        subcontractors the same way we are for ourselves. Subcontractors acting on our behalf will also
                        treat the personal data of the Data Subjects in the following cases. In the words of GDPR, they
                        are considered to be Data Processors.</p>
                    <p class="p1">During the <span class="s1"><strong>interview level data
                                controlling&nbsp;</strong></span>the data processing areas are (where data controlling
                        is done by our subcontractors)</p>
                    <ul class="ul1">
                        <li class="li5"><span class="s3">Psychologist</span></li>
                        <li class="li5"><span class="s3">Psychological Assessment Resources
                                |<span class="Apple-converted-space">&nbsp;</span></span></li>
                        <li class="li5"><span class="s3">Our partner who measures medical
                                competencies</span></li>
                        <li class="li13"><span class="s3">Proofreader</span></li>
                    </ul>
                    <p class="p12">We only use data processors who provide appropriate guarantee compliance
                        with the data controlling requirements of GDPR, and proper technical and organizational measures
                        that ensure the protection of the Data Subjects&rsquo; rights.</p>
                    <p class="p11"><strong>5.4.DATA CONTROLLING CONCERNING RELOCATION, INTEGRATION</strong>
                    </p>
                    <p class="p9"><strong>5.4.1.THE PURPOSE OF DATA CONTROLLING</strong></p>
                    <p class="p12">The Data Controller handles the preparation and certification of official
                        documents necessary for international employment and settlement, and the language preparation of
                        the Data Subject, which are included in the Services. The Data Controller manages the
                        administration of personal documentation prepared by international authorities, so that the Data
                        Subjects face fewer challenges during relocation and integration.</p>
                    <p class="p9"><strong>5.4.2.SCOPE OF CONTROLLED DATA</strong></p>
                    <p class="p3">Documents for authorization:</p>
                    <ul class="ul1">
                        <li class="li5"><span class="s3">certified copy of an official document
                                containing the name, nationality, and birth date (e.g. relevant pages of the
                                passport),</span></li>
                        <li class="li5"><span class="s3">certificate of name change, marriage
                                certificate (if there was a change of name),</span></li>
                        <li class="li5"><span class="s3">CV, education and professional
                                experience in chronological order,</span></li>
                        <li class="li5"><span class="s3">University diploma or other document
                                certifying professional education, and its translation (to English, or the destination
                                country&rsquo;s language),</span></li>
                        <li class="li5"><span class="s3">a document certifying the current
                                professional status, which is to be sent to the destination country&rsquo;s health
                                insurance authority,</span></li>
                        <li class="li5"><span class="s3">certificate of specialization, and its
                                translation (to English, or the destination country&rsquo;s language) (if
                                applicable),</span></li>
                        <li class="li5"><span class="s3">certificate of conformity prepared by
                                the relevant authority,</span></li>
                        <li class="li13"><span class="s3">authorization, if the Candidate is not
                                acting in their own name.</span></li>
                    </ul>
                    <p class="p3">Residence permit (To the destination country&rsquo;s authorities):</p>
                    <ul class="ul1">
                        <li class="li5"><span class="s3">a copy of the contract of employment,
                                or an employer&rsquo;s declaration according to the standards in the member
                                state,</span></li>
                        <li class="li5"><span class="s3">profile picture,</span></li>
                        <li class="li13"><span class="s3">all other documents requested by the
                                member state.</span></li>
                    </ul>
                    <p class="p3">Social security (to the Local Municipality Service):</p>
                    <ul class="ul1">
                        <li class="li5"><span class="s3">a completed entry form for new
                                citizens,</span></li>
                        <li class="li5"><span class="s3">a copy of passport/personal ID,</span>
                        </li>
                        <li class="li5"><span class="s3">a copy of a document certifying the
                                address (rental agreement, statement from the lessor),</span></li>
                        <li class="li5"><span class="s3">a copy of marriage certificate /
                                divorce certificate / death certificate,</span></li>
                        <li class="li13"><span class="s3">a copy of the birth certificate of
                                children under 18.</span></li>
                    </ul>
                    <p class="p3">Language preparation:</p>
                    <ul class="ul1">
                        <li class="li5"><span class="s3">basic data necessary for language
                                teaching (name, data of birth)</span></li>
                        <li class="li13"><span class="s3">photographs taken of the language
                                groups, or individuals.</span></li>
                    </ul>
                    <p class="p3">Data controlling during the relocation process, concerning accommodation:
                    </p>
                    <ul class="ul1">
                        <li class="li5"><span class="s3">providing contact data and basic
                                communication with the lessor.</span></li>
                    </ul>
                    <p class="p2"><br></p>
                    <p class="p2"><br></p>
                    <p class="p9"><strong>5.4.3.LEGAL BASIS FOR CONTROLLED DATA</strong></p>
                    <p class="p3">Primarily based on the previous information of the Data Subject, their
                        specific (in case of special category personal data, and regarding that the Data Subject can be
                        a child under 16, as a family member) and voluntary consent, both with regards to publicly
                        available data, and the data provided to us by the Data Subject. Furthermore, the legal basis of
                        data controlling can be:</p>
                    <ul class="ul1">
                        <li class="li5"><span class="s3">the completion of the contract with
                                the Data Subject concerning our Services,</span></li>
                        <li class="li13"><span class="s3">the completion of all legal
                                obligations of the Data Controller.</span></li>
                    </ul>
                    <p class="p12">In case of a child under 16, data controlling is only legal if the parent
                        or legal guardian has allowed it and given consent.</p>
                    <p class="p9"><strong>5.4.4.RETENTION TIME FOR CONTROLLED DATA</strong></p>
                    <p class="p3">Time necessary for the provision of relocation services, foreseeably 36
                        months.</p>
                    <p class="p12">Furthermore, the legal bases listed next to consent in point 3
                        (completion of contract, legal interest, and the completion of the Data Controller&rsquo;s legal
                        obligations) require, that a certain scope of personal data (data necessary for personal
                        identification, and contact) be controlled after the completion of the relocation services. The
                        contact data will be used for marketing purposes, until the Data Subject requests its erasure.
                    </p>
                    <p class="p9"><strong>5.4.5.DATA FORWARDING</strong></p>
                    <p class="p3">Data forwarding is possible to these institutions by request of the Data
                        Subject:</p>
                    <ul class="ul1">
                        <li class="li5"><span class="s3">local municipality service,</span>
                        </li>
                        <li class="li5"><span class="s3">or a relevant authority or government
                                of equal standing of the given member state of the Gulf &amp; Mena Regions.</span></li>
                        <li class="li13"><span class="s3">every authority, whose contribution is
                                necessary for the provision of Services.</span></li>
                    </ul>
                    <p class="p3">According to GDPR forwarding data to member states of the Gulf &amp; Mena
                        Regions does not have data security risks, thus the Data Subject&rsquo;s specific consent is not
                        necessary from this standpoint.</p>
                    <p class="p12">For technical processing and storing, the personal data is controlled by
                        a data processor whose servers are in the United States of America (the state of California).
                        Based on our experience we can maximize the effectiveness of our inner processes with this
                        service provider. Considering that data forwarding outside the Gulf &amp; Mena Regions requires
                        permission, we ask for the Data Subject&rsquo;s consent for this particular forwarding.</p>
                    <p class="p9"><strong>5.4.6. DATA PROCESSOR</strong></p>
                    <p class="p3">Given that our Services are complex, time-consuming activities requiring
                        special expertise, we use subcontractors to provide them. We are accountable for our
                        subcontractors the same way we are for ourselves. Subcontractors acting on our behalf will also
                        treat the personal data of the Data Subjects in the following cases. In the words of GDPR, they
                        are considered to be Data Processors.</p>
                    <p class="p3">Data processors necessary for relocation, integration related data
                        controlling (where the data controlling is done by our subcontractors):</p>
                    <ul class="ul1">
                        <li class="li5"><span class="s3">authorities, offices of destination
                                country,</span></li>
                        <li class="li5"><span class="s3">language teachers,</span></li>
                        <li class="li13"><span class="s3">apartment owners, lessors.</span></li>
                    </ul>
                    <p class="p3">We only use data processors who provide appropriate guarantee compliance
                        with the data controlling requirements of GDPR, and proper technical and organizational measures
                        that ensure the protection of the Data Subjects&rsquo; rights.</p>
                    <p class="p9"><strong>6.DATA PROTECTION</strong></p>
                    <p class="p3">The Data Controller will take the necessary technical and organizational
                        measures and establish appropriate procedural rules to ensure the security of personal data
                        throughout the entire process of data management.</p>
                    <p class="p3">The Data Controller chooses and controls the IT tools used for the
                        management of personal data in a way that the handled data:</p>
                    <ul class="ul1">
                        <li class="li5"><span class="s3">is accessible for authorized personnel
                                (availability);</span></li>
                        <li class="li5"><span class="s3">its authenticity and authentication
                                are provided (authenticity of data management);</span></li>
                        <li class="li5"><span class="s3">its uniformity is verifiable (data
                                integrity);</span></li>
                        <li class="li13"><span class="s3">it is protected against unauthorized
                                access (data privacy).</span></li>
                    </ul>
                    <p class="p3">The Data Controller</p>
                    <ul class="ul1">
                        <li class="li5"><span class="s3">protects the data by enforcing
                                appropriate measure against accidental or unlawful destruction, loss, modification,
                                damage, unauthorized publication, or unauthorized access.</span></li>
                        <li class="li5"><span class="s3">limits access to the personal data by
                                establishing authorization levels,</span></li>
                        <li class="li5"><span class="s3">protects IT systems by using a fire
                                wall and virus protection,</span></li>
                        <li class="li5"><span class="s3">ensures that in the process of
                                electronic data management the data is only accessible for an intended purpose, under
                                controlled circumstances, by those who need it in order to perform their duties,</span>
                        </li>
                        <li class="li13"><span class="s3">provides appropriate technical
                                solutions for the protection of files managed in various electronic databases, in order
                                to ensure that the stored data &ndash; unless it is made legally possible &ndash;
                                &nbsp;is not directly linked or assigned to the Data Subject.</span></li>
                    </ul>
                    <p class="p3">In the light of the current state of the art, the Data Controller
                        provides technical, organizational and corporate measures to protect the security of data
                        management, which provides a level of protection that corresponds to the risks associated with
                        data management.</p>
                    <p class="p3">The Data Controller records any possible privacy incidents, indicating
                        facts tied to these, their effects, and measures taken as remedies. The Data Controller reports
                        the possible incidents without delay, preferably within 72 hours of realizing the occurrence of
                        the privacy incident, to the National Data Protection and Freedom of Information Authority
                        (hereinafter: Authority), unless the privacy incident is not likely to pose a risk to the rights
                        and freedoms of natural persons.</p>
                    <p class="p9"><strong>7. DATA SUBJECT&rsquo;S RIGHTS AND THE CONDITIONS FOR ENFORCING
                            THEM</strong></p>
                    <p class="p12">The Data Subject may request information concerning the management of
                        their personal data, and the rectification of their personal data, furthermore, they may request
                        the deletion of their personal data&ndash; except for statutory data management &ndash; as
                        specified during the data collection process, or through the customer service.</p>
                    <p class="p9"><strong>7.1.RIGHT TO INFORMATION</strong></p>
                    <p class="p12">At the request of the Data Subject, the Data Controller shall provide
                        information on the data, source of the data, purpose of the data processing, legal basis,
                        duration of the data processing, the name and address of the Data Processor, and the activities
                        concerning data processing of the data managed by the Data Controller, or by the assigned Data
                        Processor, furthermore, in case the Data Subject&rsquo;s data has been forwarded, on the legal
                        basis and addressee of the transmission. The data controller shall provide the information in
                        writing, in a clear form, within the shortest possible time from the submission of the request,
                        but not later than within 25 days. Information is free of charge if the requested information
                        has not yet been filed with the Data Controller for the same data field in the current year. In
                        other cases, reimbursement can be determined. The Data Controller may refuse to inform the Data
                        Subject only in statutory cases In the event of non-disclosure, the data controller shall inform
                        the data subject in writing that refusal of the information has been made under the provisions
                        of the Information Act. In the case of information refusal, the Data Controller notifies the
                        Data Subject about the possibility of legal redress, and of turning to the Authority.</p>
                    <p class="p9"><strong>7.2.RIGHT TO CORRECTION</strong></p>
                    <p class="p12">If the personal data does not comply with reality and the personal data
                        corresponding to reality is available to the Data Controller, the personal data will be
                        corrected by the Data Controller.</p>
                    <p class="p9"><strong>7.3.RIGHT TO ERASURE</strong></p>
                    <p class="p12">Personal data must be erased if its handling is illegal; if the Data
                        Subject requests it (except for compulsory data management); if it is incomplete or incorrect,
                        and this cannot be legally remedied, provided that erasure is not legally forbidden; if the
                        purpose of data management has ceased, or if the legally declared deadline for data storing has
                        expired; if it has been ordered so by the court or the Authority.</p>
                    <p class="p9"><strong>7.4.RIGHT TO RESTRICTION OF PROCESSING</strong></p>
                    <p class="p12">Instead of being deleted, the Data Controller will block the personal
                        data if the Data Subject so requests or if, on the basis of the information available to him, it
                        is assumed that the deletion would harm the legitimate interests of the Data Subject. Personal
                        data so locked up can only be handled as long as there is a data management purpose that
                        excludes the erasure of personal data. &nbsp;The Data Controller shall indicate the personal
                        data they manage if the Data Subject disputes its correctness or accuracy, but the incorrect or
                        inaccurate nature of the disputed personal data can not be clearly identified.</p>
                    <p class="p9"><strong>7.5.OBLIGATION TO CORRECT OR ERASE PERSONAL DATA, AND TO NOTIFY
                            ON RESTRICTIONS OF DATA MANAGEMENT</strong></p>
                    <p class="p12">The Data Controller informs the Data Subject regarding correction,
                        restriction and erasure. Notification may be omitted if it does not prejudice the legitimate
                        interest of the Data Subject for the purpose of data handling. If the Data Controller fails to
                        complete the Data Subject&rsquo;s request for rectification, restriction or erasure, they shall
                        within 30 days acknowledge the factual and legal grounds for refusal of the correction,
                        restriction or erasure request. &nbsp;In the case of refusal of an application for
                        rectification, erasure or restriction, the Data Controller shall inform the Data Subject of the
                        judicial remedy and of the possibility of appeal to the Authority.</p>
                    <p class="p9"><strong>7.6.RIGHT TO OBJECTION</strong></p>
                    <p class="p3">The Data subject is entitled to object any time for reasons concerning
                        their own situation to the processing of data necessary for the execution of a task performed in
                        the public interest or in the exercise of a public authority exercised on the Data Controller or
                        for the treatment of the legitimate interests of the data controller or a third party, including
                        profiling based on those provisions too.</p>
                    <p class="p3">In the event of an objection, the Data Controller shall not process the
                        personal data unless it is justified by compelling reasons of lawfulness which prevail over the
                        interests, rights and freedoms of the Data Subject, or which relate to the submission,
                        enforcement or protection of legal claims.</p>
                    <p class="p12">The Data Controller shall examine the objection within the shortest time
                        possible, but at most within 15 days of the submission of the request, decide on the matter of
                        its validity and inform the applicant in writing. &nbsp;If the Data Controller establishes the
                        validity of their objection, data management &ndash; including further data collection and data
                        transfer &ndash; will terminate and data shall be locked, moreover, the Data Controller informs
                        those about the measures taken who have been previously sent the data which is the subject of
                        the objection, and those who are obligated to take measures in order to enforce the right to
                        objection. If the Data Subject disagrees with the decision of the Data Controller or if the Data
                        Controller fails to comply with the statutory deadline, the concerned party may refer the case
                        to the court within 30 days from the date of notification of the decision or from the last day
                        of the deadline. The Data Controller can also sue the Data Subject. The Data Controller can not
                        erase the relevant data if the data processing is ordered by law. However, the data can not be
                        forwarded to the data receiver if the Data Controller agrees to the objection or the court has
                        found the objection rightful.</p>
                    <p class="p9"><strong>7.7.RIGHT TO STORE DATA</strong></p>
                    <p class="p12">The Data Subject shall have the right to receive the personal data that
                        they have commissioned to the Data Controller in a fragmented, widely used machine-readable
                        format and forward this data to another Data Controller.</p>
                    <p class="p9"><strong>7.8.POSSIBILITY OF JUDICIAL REMEDY</strong></p>
                    <p class="p3">In the event of violation of their rights and in the cases specified by
                        law, the Data Subject may turn to the court against the Data Controller. The court proceeds in
                        urgency.</p>
                    <p class="p3">If the Data Subject has suffered material or non-material damage as a
                        result of the breach of the Data Protection Regulation, they are entitled to compensation for
                        the damage sustained by the Data Controller or the Data Processor. The Data Controller or the
                        Data Processor shall be exempt from liability if they prove that the damage was caused by an
                        unavoidable cause outside the scope of data management. There is no need to reimburse the damage
                        in so far as it is due to the intentional or gross negligence of the injured party.</p>
                    <p class="p3">Submitting an objection or complaint does not affect the other rights
                        &ndash; regulated in the data protection acts &ndash; of the Data Subject.</p>
                    <p class="p3">The Data Protection Officer (hereinafter: DPO) is involved in the
                        processing of the complaint. The Data Subject can submit a complaint to:</p>
                    <ul class="ul1">
                        <li class="li5"><span class="s3">Medical Job<span
                                    class="Apple-converted-space">&nbsp;</span></span></li>
                        <li class="li5"><span class="s3">Lebanese Court</span></li>
                    </ul>        
        `,
  }),
};
</script>

<style lang="scss">
.static-page {
  background: white;
  border-radius: 20px;
  padding-top: 20px;

  &__max-width {
    max-width: 700px;
    margin: auto;
  }
}

.m-20 {
  margin-right: 5%;
  margin-left: 5%;
  margin-bottom: 5%;
}
</style>
